<template>
  <div class="main">
    <div class="bg"></div>
    <div class="container">
      <img id="logo" src="@/assets/img/logo1.png" alt="LGMusic" @click="back()" />
      <p>ヽ(ー_ー)ノ啊哦，您要找的页面走丢了！</p>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      back() {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .main {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 14px;
    font-family: 'helvetica neue', tahoma, arial, 'hiragino sans gb', 'microsoft yahei', 'Simsun', sans-serif;
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-image: url(../../assets/background/bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    filter: blur(5px);
    opacity: 0.8;
    transition: all 0.8s;
    transform: scale(1.1);
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 5%;
  }

  img {
    width: 250px;
    height: 70px;
    margin-bottom: 100px;
  }

  p {
    width: 100%;
    font-size: 1.5rem;
    background: #fc00ff;
    background: -webkit-linear-gradient(to right, #00dbde, #fc00ff);
    background: linear-gradient(to right, #00dbde, #fc00ff);
    -webkit-background-clip: text;
    background-size: contain;
    color: transparent;
  }
</style>
